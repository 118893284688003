import React, { useEffect, useState } from 'react';
import {useInView} from 'react-intersection-observer'


function useFadeIn (scrolled = 0.2,className = '') {
    const { ref, inView } = useInView({ threshold:scrolled})
    const [classNames,setClassNames] = useState(['fade',className].join(' '))

    useEffect(() =>{
        if(window.innerWidth > 768){
            if(inView && !classNames.includes('in')) {
                setClassNames((state) => [state,'in'].join(' '))
            }
        }
    },[classNames,scrolled,inView])
   
     return{ classNames,ref}
}

export default function FadeInView({children,scrolled,className}) {
    const {ref,classNames} = useFadeIn(0.2,className)
    return (
        <div ref={ref}  className={classNames}>
            {children}
        </div>
    );
}
 