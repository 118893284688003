import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import Link from '../link'
import ReactPlayer from 'react-player'
function SquareScalingBanner({ children, wrapperClassName, link, image, container, video }) {
    return (
        <div className={'scaling--container--square ' + container}>
            <Link to={link} className='r6 '>
                <div className='pt50 bg-wrapper r6'>
                    <div className='bg-image z-1 scale--up r6'>
                        <div className='rel h100'>
                            {video ?
                                <ReactPlayer loop={true} width='100%' height='100%' className='h100 w100 bg-image r6' playsinline={true} muted={true} playing={true} url={video} />
                                :
                                <GatsbyImage alt="" className='h100 w100 r6 ' image={image?.gatsbyImageData} />
                            }
                        </div>
                    </div>
                    <div className={'bg-image flex ju-c al-i-c flex-c z-2 ' + wrapperClassName}>
                        <div className='flex ju-c al-i-c  flex-c'>
                            {children}
                        </div>
                    </div>
                </div>
            </Link>
        </div>

    )
}

export default SquareScalingBanner
